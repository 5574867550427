@font-face {
    font-family: 'Road Rage';
    src: url('fonts/RoadRage.eot');
    src: url('fonts/RoadRage.eot?#iefix') format('embedded-opentype'),
        url('fonts/RoadRage.woff2') format('woff2'),
        url('fonts/RoadRage.woff') format('woff'),
        url('fonts/RoadRage.ttf') format('truetype'),
        url('fonts/RoadRage.svg#RoadRage') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* Variables */
$blue: #68b7fa;
$tan: #fdecdf;
$white: #ffffff;
$black: #000000;

*,
*::before,
*::after {
    box-sizing: border-box;
}

::selection {
    background-color: $black;
    color: white;
}

html {
    background-color: $black;
    overflow-x: hidden;
}

body {
    max-width: 3840px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    background-color: $black;
    overflow-x: hidden;
    font-family: "map-roman-variable", serif;
    font-variation-settings: "wdth" 100;
    // filter: url('#noise') contrast(110%);
}

#overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 99999999;
    img {
        max-width: 800px;
        width: 100%;
        @media only screen and (min-width: 1024px) and (max-height: 750px) {
            max-width: 400px;
        }
    }
    button {
        font-family: 'Road Rage';
        font-size: 4rem;
        line-height: 1;
        display: inline-flex;
        color: #ffffff;
        background: transparent !important;
        width: auto;
        z-index: 2;
        text-shadow:
        0 0 5px rgba(255, 255, 255, 0.8),
        0 0 10px rgba(255, 255, 255, 0.6),
        0 0 15px rgba(255, 255, 255, 0.4);
        padding-left: 30px;
        padding-right: 24px;
        border: 0;
        transform: scale(1);
        transition: .3s all ease;
        margin-top: 20px;
        @media only screen and (max-width: 600px) {
            font-size:3rem;
        }

        @media only screen and (max-width: 430px) {
            font-size:2rem;
            margin-top: 10px;
        }
    
        &:hover {
            cursor: pointer;
            transform: scale(1.10);
    
            @media only screen and (max-width: 600px) {
                transform: scale(1);
            }
        }
    }
}

#begin-btn {
    opacity: 0;
    animation: fadeIn 0.5s ease-in 0.5s forwards;
}

/* Keyframe for the fade-in effect */
@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
    /* 3 */
}

.content-container {
    position: relative;
    margin-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    top: 12px;
}

h2 {
    color: $white;
    text-align: center;
    width: 100%;
    display: block;
    margin: 0 auto;
    font-size: 24px;
    line-height: 1.25;

    @media only screen and (max-width: 1024px) {
        font-size: 18px;
        line-height: 1.50;
    }

    @media only screen and (max-width: 1024px) {
        max-width: 520px;
    }

    @media only screen and (max-width: 600px) {
        font-size: 16px;
    }

    @media only screen and (max-width: 500px) {
        font-size: 15px;
    }

    @media only screen and (max-width: 470px) {
        font-size: 22px;
    }

    @media only screen and (max-width: 410px) {
        font-size: 21px;
    }

    @media only screen and (max-width: 376px) {
        font-size: 20px;
    }

    @media only screen and (max-width: 374px) {
        font-size: 18px;
    }
    @media only screen and (max-width: 350px) {
        font-size: 14px;
    }

}

button, input[type="button"] {
    -webkit-appearance: none;
}

#rapper-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* 4 columns by default */
    grid-gap: 25px;
    /* 25px gap between images */
    max-width: 1920px;
    /* Max width of the container */
    margin: 0 auto;
    /* Center the container */
    padding: 25px 25px 150px 25px;

    .rapper {
        border: 5px solid white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s all ease;
        transform: scale(1);
        filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0));

        .role {
            font-size: 24px;
            line-height: 1;
            text-align: center;
            font-family: 'Road Rage';
            color: $black;
            position: absolute;
            bottom: -1px;
            display: block;
            background: white;
            width: 100%;
            padding:8px 5px 5px 5px;
            @media only screen and (max-width: 1100px) {
                font-size: 20px;
            }
            @media only screen and (max-width: 550px) {
                font-size: 16px;
            }
            @media only screen and (max-width: 400px) {
                font-size: 15px;
            }
            @media only screen and (max-width: 374px) {
                font-size: 14px;
            }
            @media only screen and (max-width: 350px) {
                font-size: 13px;
            }
        }

        .life {
            font-size: 12px;
            font-style: italic;
            line-height: 1;
            text-align: center;
            color: $black;
            background-color: white;
            position: absolute;
            bottom: 35px;
            display: block;
            width: 100%;
            padding:8px 5px 5px 5px;
            opacity: 0;
            transition: .3s all ease;

            @media only screen and (max-width: 1280px) {
                bottom:30px;
            }

            @media only screen and (max-width: 600px) {
                bottom:25px;
            }

            @media only screen and (max-width: 470px) {
                font-size: 11px;
                bottom:20px;
            }

            @media only screen and (max-width: 450px) {
                font-size: 10px;
            }

            @media only screen and (max-width: 410px) {
                font-size: 9px;
                bottom: 25px;
            }

            @media only screen and (max-width: 375px) {
                font-size: 8px;
                bottom: 20px;
            }

            @media only screen and (max-width: 350px) {
                display: none;
            }
        }

        &:hover {
            transform: scale(1.05);

            @media only screen and (max-width: 600px) {
                transform: scale(1);
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.active {
            // display: block;
            // filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.8));
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.8);
            position: relative;

            @media only screen and (max-width: 1024px) {
                // filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.8));
                box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
            }

            .life {
                opacity: 1;
            }

            &:before {
                content: '';
                width: 150px;
                height: 94px;
                display: block;
                background: url('images/active-rapper-halo.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                position: absolute;
                top: -30px;
                left: -35px;
                transform: rotate(-20deg);
                pointer-events: none;

                @media only screen and (max-width: 1024px) {
                    width: 100px;
                    height: 58px;
                    top: -19px;
                    left: -23px;
                }
            }
        }
    }

    // @media (max-width: 1024px) {
    //     grid-template-columns: repeat(3, 1fr);
    //     /* Switch to 3 columns */
    // }

    @media (max-width: 999px) {
        grid-template-columns: repeat(2, 1fr);
        /* Switch to 2 columns */
        padding-bottom: 110px;
    }
}

#sound-control {
    position: fixed;
    left: 0;
    bottom: 80px;
    font-family: 'Road Rage';
    font-size: 2rem;
    line-height: 1;
    display: inline-flex;
    color: #ffffff;
    // background: #ffffff;
    background: transparent !important;
    width: auto;
    z-index: 2;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // filter: url(#softGlow);
    // z-index: 1;
    padding-left: 30px;
    padding-right: 24px;
    border: 0;
    transform: scale(1);
    transition: .3s all ease;
    &:hover {
        cursor: pointer;
        transform: scale(1.10);
        @media only screen and (max-width: 600px) {
            transform: scale(1);
        }
    }
    @media (max-width: 999px) {
        font-size: 22px;
        padding-left: 20px;
        bottom: 75px;
    }
}

.banner-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 3;

    &:after {
        content: '';
        height: 100%;
        width: 50px;
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

        @media only screen and (max-width: 415px) {
            width: 30px;
        }
    }
}

#banner {
    height: 60px;
    font-size: 16px;
    color: $white;
    background-color:$black;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-top: 2px solid #333;
    @media only screen and (max-width: 415px) {
        font-size: 13px;
    }
}

#banner span {
    display: inline-block;
    padding-right: 50px;
    /* Add space between repeated text */
}

#banner-title {
    background: black;
    border-top: 2px solid #333;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 245px;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    @media only screen and (max-width: 1024px) {
        width: 205px;
    }
    @media only screen and (max-width: 415px) {
        padding-left: 5px;
        padding-right: 5px;
        width: 160px;
    }
    &:after{
        content: '';
        height: 100%;
        width: 50px;
        display: block;
        position: absolute;
        bottom: 0;
        right: -50px;
        background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        @media only screen and (max-width: 1024px) {
            right: -40px;
        }
        @media only screen and (max-width: 415px) {
            width: 30px;
            right: -30px;
        }
    }
    span {
        color: $white;
        display: block;
        font-size: 20px;
        position: relative;
        top: 2px;
        padding-left: 10px;
        font-style: italic;
        font-weight: bold;
        @media only screen and (max-width: 1024px) {
            font-size: 18px;
        }
        @media only screen and (max-width: 415px) {
            font-size: 14px;
        }
    }
    img {
        width: 30px;
        margin-left: 0;
        position: absolute;
        top: 13px;
        left: 22px;
        transform: rotate(-15deg);
        @media only screen and (max-width: 1024px) {
            width: 24px;
            top: 15px;
        }
        @media only screen and (max-width: 415px) {
            width: 20px;
            top: 19px;
            left: 12px;
        }
    }
}

#banner .ticker {
    display: inline-block;
    animation: marquee 20s linear infinite;
    position: relative;
    top: 2px;
    /* Adjust speed as needed */

    @media only screen and (max-width: 415px) {
        animation: marquee 10s linear infinite;
    }
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

#contract-address-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 75px;
    width: 450px;
    margin-left: auto;
    margin-right: auto;
    background:transparent;
    z-index: 2;
    background:transparent;
    @media (max-width: 999px) {
        position: relative;
        padding-bottom: 80px;
    }
    @media only screen and (max-width: 470px) {
        flex-direction: column;
        width: auto;
    }
    .copy {
        display: none;
        font-family: "map-roman-variable", serif;
        text-transform: uppercase;
        padding-left: 10px;
        font-size: 18px;
        position: relative;
        top: -4px;
        @media only screen and (max-width: 470px) {
            display: inline-block;
        }
    }
}

#contract-address {
    font-size: 18px;
    color: #f4f4f4;
    padding: 10px 0 10px 10px;
    border-radius: 4px;
    position: relative;
    top: 1px;
    @media only screen and (max-width: 640px) {
        font-size: 16px;
        padding-left: 0;
        padding-right: 0;
    }
    @media only screen and (max-width: 540px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 470px) {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 90%;
        display: block;
        overflow: hidden
    }
}

#copy-btn {
    background:transparent;
    color: white;
    border: none;
    padding: 0;
    border-radius: 4px;
    cursor: pointer;
    transform: scale(1);
    transition: .3s all ease;
    img {
        width: 20px;
        height: 20px;
    }
}

#copy-btn:hover {
    transform: scale(1.10);
}

#copy-status {
    font-size: 18px;
    color: white;
    margin-left: 10px;
    position: absolute;
    top: -30px;
    text-transform: none;
    img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 2px;
    }
}

.socials {
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: fixed;
    right: 10px;
    bottom: 50px;
    z-index: 2;
    li {
        margin-left: 6px;
        margin-right: 6px;
        @media only screen and (max-width: 999px) {
            width: 40px;
            height: 40px;
        }
        @media only screen and (max-width: 374px) {
            width: 35px;
            height: 35px;
            margin-left: 4px;
            margin-right: 4px;
        }
        a {
            transition: .3s all ease;
            transform: scale(1);
            display: block;
        }
        a:hover {
            transform: scale(1.10);
            @media only screen and (max-width: 600px) {
                transform: scale(1);
            }
        }
        img {
            width: 50px;
            height: 50px;
            filter: drop-shadow(0 0 7px rgba(255, 255, 255, 0.8));
            @media only screen and (max-width: 999px) {
                width: 40px;
                height: 40px;
            }
            @media only screen and (max-width: 374px) {
                width: 35px;
                height: 35px;
            }
        }
    }
    @media only screen and (max-width: 999px) {
        bottom: 55px;
    }
    @media only screen and (max-width: 374px) {
        bottom: 57px;
    }
}

/* TXT TEST */

// @font-face {
//     font-family: 'RoadRage';
//     font-style: normal;
//     font-weight: normal;
//     src: local('RoadRage'), url('http://coding-dude.com/wp/wp-content/uploads/Road_Rage.woff') format('woff');
// }

.centered {
    position: relative;
    left: 0;
    top: 0;
    text-align: center;
    margin-top: 50px;
    user-select: none;
    z-index: 9999999;
    display: block;
    // transform: translateX(-50%) translateY(-50%);
    @media only screen and (max-width: 415px) {
        margin-top: 25px;
    }
}

// STACKED RED
// .road-rage {
//     font-family: 'Road Rage';
//     font-size: 10rem;
//     font-style: italic;
//     line-height: 75%;
//     display: inline-block;
//     transform: rotate(-5deg);
//     color: #fe417b;
//     background: #fe417b;
//     width: 100%;
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     filter: url(#softGlow);
//     z-index: -1;
// }

// .shine:after {
//     // text-shadow:0 0 10px #fe417b, 0 0 100px #fe417b;
//     content: attr(data-text);
//     background-image: linear-gradient(0deg, transparent 0%, rgba(255, 255, 255, 0.5) 35%, transparent 60%);
//     mix-blend-mode: soft-light;
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     -webkit-background-clip: text;
//     background-size: 100% 100%;
//     background-position: 100% 50%;
//     z-index: 999;
// }

// HORIZONTAL RED
// .road-rage {
//     font-family: 'Road Rage';
//     font-size: 12rem;
//     font-style: italic;
//     line-height: 1;
//     display: inline-flex;
//     /* This keeps the inline behavior but allows for better layout control */
//     // transform: rotate(-5deg);
//     color: #fe417b;
//     background: #fe417b;
//     width: auto;
//     /* Let the text content define the width */
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     filter: url(#softGlow);
//     z-index: 1;
//     position: relative;
//     position: relative;
//     padding-left: 3rem;
//     /* Add small padding */
//     padding-right: 3rem;
//     /* Add small padding */
//     /* Ensures the ::after element is positioned relative to this element */
// }

// .shine::after {
//     content: attr(data-text);
//     background-image: linear-gradient(0deg, transparent 0%, softGlowrgba(255, 255, 255, 0.5) 35%, transparent 60%);
//     mix-blend-mode: soft-light;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     /* Ensures it takes the full width of the text */
//     height: 100%;
//     /* Full height of the parent element */
//     -webkit-background-clip: text;
//     background-size: 100% 100%;
//     background-position: 100% 50%;
//     z-index: 999;
// }

// .road-rage.offset {
//     margin-left: -50px;
// }

// HORIZONTAL WHITE
.road-rage {
    font-family: 'Road Rage';
    font-size: 12rem;
    font-style: italic;
    line-height: 1;
    display: inline-flex;
    color: #ffffff;
    /* White text */
    background: #ffffff;
    /* White background for the text fill */
    width: auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: url(#softGlow);
    z-index: 1;
    position: relative;
    padding-left: 3rem;
    padding-right: 3rem;

    @media only screen and (max-width: 1440px) {
        font-size: 10rem;
    }
    @media only screen and (max-width: 1225px) {
        font-size: 8rem;
    }
    @media only screen and (max-width: 1024px) {
        font-size: 7rem;
    }
    @media only screen and (max-width: 900px) {
        font-size: 6rem;
    }
    @media only screen and (max-width: 724px) {
        font-size: 5rem !important; 
    }
    @media only screen and (max-width: 374px) {
        font-size: 3rem !important;
    }
}

.shine::after {
    content: attr(data-text);
    background-image: linear-gradient(0deg, transparent 0%, rgba(255, 255, 255, 0.5) 35%, transparent 60%);
    mix-blend-mode: soft-light;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-background-clip: text;
    background-size: 100% 100%;
    background-position: 100% 50%;
    z-index: 999;
}

.road-rage.offset {
    margin-left: -50px;

    @media only screen and (max-width: 724px) {
        margin-left: -10px;
    }

    @media only screen and (max-width: 415px) {
        margin-left: -20px;
    }
}


.gradient-bottom {
    position: fixed;
    bottom: 60px;
    left: 0;
    width: 100%;
    height: 125px;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    z-index: 1;
    pointer-events: none;
    /* So it doesn't interfere with any clickable elements */
    transform: scaleY(1);
    opacity: 1;
}

/* Animation for the gradient effect */
// @keyframes gradientAnimation {
//     0% {
//         transform: scaleY(1);
//         opacity: 1;
//     }

//     50% {
//         transform: scaleY(1.2);
//         /* Grow a bit */
//         opacity: 0.7;
//         /* Change opacity */
//     }

//     100% {
//         transform: scaleY(1);
//         opacity: 1;
//     }
// }

.glow-svg {
    height: 0;
}